import { useRef } from "react";
import {
  useEnteredViewport,
  useStartAnim,
  useViewportSize,
} from "../core/hooks";

import AnimatedHeadingGradientLast from "./AnimatedHeadingGradientLast";
import Box from "./Box";
import CapSize from "./CapSize";
import FadeOnScroll from "./FadeOnScroll";
import Hero from "./Hero";
import LottiePlayer from "./LottiePlayer";
import NarrowCol from "./NarrowCol";
import { hero } from "../content/index";
import linesAnimData from "../assets/HP_Lines_3000x1400_V6-electrons.json";
import linesAnimDataMobile from "../assets/HP_Lines_960x960_mobile.json";

function HomepageHero({ locale }) {
  const { innerHeight } = useViewportSize();
  const startAnim = useStartAnim();
  const { ref, entered } = useEnteredViewport(0);
  const { isDesktop } = useViewportSize();
  const playerRef = useRef();

  const animStyles = isDesktop
    ? {
        width: "2500px",
        height: "1400px",
      }
    : {
        width: "1024px",
        height: "1024px",
      };

  const heroContent = hero[locale];

  return (
    <Hero anchorPointId="homepage-anchor" transitionDelay={1000} hasArrowButton>
      <Box
        height={innerHeight}
        position="fixed"
        top={0}
        left={0}
        width="100%"
        zIndex={-1}
      >
        <Box
          height="100%"
          width="100%"
          background="linear-gradient(132deg, #05004f, #030034 67%, #020027)"
          overflow="hidden"
          mx="auto"
          display="flex"
          justifyContent="center"
          alignItems={["flex-start", null, "center", null]}
        >
          <LottiePlayer
            ref={playerRef}
            src={isDesktop ? linesAnimData : linesAnimDataMobile}
            loop={true}
            autoplay={true}
            rendererSettings={{
              preserveAspectRatio: "xMidYMid meet",
            }}
            style={animStyles}
            pauseWhenNotInView={false}
          />
        </Box>
      </Box>
      <FadeOnScroll>
        <NarrowCol
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          width="100%"
          maxWidth={[500, 1280, 1280, null]}
          height="100%"
        >
          <AnimatedHeadingGradientLast
            animate={startAnim}
            text={heroContent.heading.text}
            gradientText={heroContent.heading.gradientText}
            textAlign="center"
            baseDelay={350}
          />
          <Box
            maxWidth={[300, 500, 870, null]}
            margin="0 auto"
            pt={[27, null, 50, null]}
            ref={ref}
            transition="opacity 800ms"
            transitionDelay={650}
            opacity={entered && startAnim ? 1 : 0}
          >
            <CapSize
              as="p"
              textAlign="center"
              fontSize={[15, 15, 30, 30]}
              lineHeight={["25px", "25px", "40px", "40px"]}
            >
              {heroContent.subheading}
            </CapSize>
          </Box>
        </NarrowCol>
      </FadeOnScroll>
    </Hero>
  );
}

export default HomepageHero;
