import BodyTextLarge from "./BodyTextLarge";
import Box from "./Box";
import BoxWithGutter from "./BoxWithGutter";
import MobileDiagrams from "./MobileDiagrams";
import NarrowCol from "./NarrowCol";
import TwoColLottieScrollToPlay from "./TwoColLottieScrollToPlay";
import ViewportHeightBox from "./ViewportHeightBox";
import { diagrams } from "../content/index";

function HomepageDiagrams({ locale }) {
  const diagramItems = diagrams[locale];

  return (
    <BoxWithGutter backgroundColor="trailstoneNavy">
      <NarrowCol>
        <Box display={["none", null, "initial", null]}>
          <TwoColLottieScrollToPlay
            animData={diagrams.mainAnimData}
            col1Content={
              <>
                {diagramItems.map((item) => {
                  return (
                    <ViewportHeightBox key={item.heading}>
                      <Box maxWidth={500}>
                        <BodyTextLarge color="lime">
                          {item.heading}
                        </BodyTextLarge>
                        <BodyTextLarge>{item.text}</BodyTextLarge>
                      </Box>
                    </ViewportHeightBox>
                  );
                })}
              </>
            }
          />
        </Box>
      </NarrowCol>
      <MobileDiagrams items={diagramItems} />
    </BoxWithGutter>
  );
}

export default HomepageDiagrams;
