import BoxWithGutter from "./BoxWithGutter";
import HeadingAndCards from "../components/HeadingAndCards";
import { skills } from "../content/index";

function HomepageSkills({ locale }) {
  const skillsContent = skills[locale];
  return (
    <BoxWithGutter
      backgroundColor="indigo"
      pt={[75, null, 120]}
      pb={[80, null, 150, null]}
    >
      <HeadingAndCards
        heading={skillsContent.heading}
        cards={skillsContent.cards}
        narrowHeading={true}
        colsNo={3}
        numberedCards={false}
      />
    </BoxWithGutter>
  );
}

export default HomepageSkills;
