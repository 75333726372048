import BoxWithGutter from "./BoxWithGutter";
import HeadingAndDotLineTextItems from "./HeadingAndDotLineTextItems";
import { vision } from "../content/index";

function HomepageVision({ locale }) {
  const visionContent = vision[locale];
  return (
    <BoxWithGutter
      backgroundColor="trailstoneNavy"
      pb={[30, null, 100, null]}
      pt={[30, null, 0, null]}
    >
      <HeadingAndDotLineTextItems
        mainHeading={visionContent.heading}
        items={visionContent.items}
      />
    </BoxWithGutter>
  );
}

export default HomepageVision;
