import diagramsAnimData from "../assets/diagram-combined.json";
import energyAnimData from "../assets/hp-energy.json";
import executionAnimData from "../assets/HP-skills-icon-execution.json";
import innovationAnimData from "../assets/HP-skills-icon-innovation.json";
import optimisationAnimData from "../assets/HP-skills-icon-optimisation.json";
import plugAnimData from "../assets/diagram-pt3-plug-mobile.json";
import pylonAnimData from "../assets/diagram-pt2-pylon-mobile.json";
import windmillAnimData from "../assets/diagram-pt1-windmill-mobile.json";

export const metaTitle = {
  en: "Making the world’s transition to sustainable energy sustainable.",
  de: "Der nachhaltige Weg zu erneuerbarer Energie.",
  fr: "Rendre durable la transition mondiale vers des énergies durables",
  ja: "世界の持続可能エネルギーへの移行を持続可能なものへ",
};

export const metaDescription = {
  en: "We are a global energy and technology company, operating at the intersection of renewable and conventional power.",
  de: "Als internationales Energie- und Technologieunternehmen arbeiten wir an der Schnittstelle von erneuerbarer und konventioneller Energie.",
  fr: "Nous sommes une entreprise globale alliant énergie et technologie qui opère à la croisée des énergies renouvelables et conventionnelles.",
  ja: "私たちは、再生可能エネルギーと従来型電力の交わりの中で事業を展開する、グローバルなエネルギー及びテクノロジー企業です。",
};

export const hero = {
  en: {
    heading: {
      text: "Making the world’s transition to sustainable energy",
      gradientText: "sustainable ",
    },
    subheading:
      "We are a global energy and technology company, operating at the intersection of renewable and conventional power.",
  },
  de: {
    heading: {
      text: "Der nachhaltige Weg zu erneuerbarer Energie",
    },
    subheading:
      "Als internationales Energie- und Technologieunternehmen arbeiten wir an der Schnittstelle von erneuerbarer und konventioneller Energie.",
  },
  fr: {
    heading: {
      text: "Rendre durable la transition mondiale vers des énergies durables",
    },
    subheading:
      "Nous sommes une entreprise globale alliant énergie et technologie qui opère à la croisée des énergies renouvelables et conventionnelles.",
  },
  ja: {
    heading: {
      text: "世界の持続可能エネルギーへの移行を持続可能なものへ",
    },
    subheading:
      "私たちは、再生可能エネルギーと従来型電力の交わりの中で事業を展開する、グローバルなエネルギー及びテクノロジー企業です。",
  },
};

export const challenge = {
  videoSrc: "/video/hp-video.mp4",
  imageSrc: "/images/Globe-First-Frame-1280x720.jpg",
  en: {
    headingWithLine: "The challenge",
    limeText: "Energy is at the heart of tackling the climate crisis.",
    text1:
      "Power generation is responsible for a quarter of global greenhouse gas emissions.",
    text2:
      "To fulfil the potential of sustainable energy, transform electricity systems around the world and decarbonize the grid, we must solve the problem of renewable intermittency.",
    buttonText: "Solutions",
  },
  de: {
    headingWithLine: "Die Herausforderung",
    limeText:
      "Energie ist ein auschlaggebender Faktor für die Bewältigung der Klimakrise.",
    text1:
      "Die Stromerzeugung ist für ein Viertel der weltweiten Treibhausgasemissionen verantwortlich.",
    text2:
      "Um das Potenzial von erneuerbarer Energie voll auszuschöpfen, den Strommarkt weltweit anzupassen sowie das Stromnetz CO2-neutral umzustellen, müssen wir das Problem der volatilen erneuerbaren Erzeugung lösen.",
    buttonText: "Lösungen",
  },
  fr: {
    headingWithLine: "Le défi",
    limeText:
      "L'énergie est au cœur de la lutte contre le changement climatique.",
    text1:
      "La production d'électricité est responsable d'un quart des émissions mondiales de gaz à effet de serre.",
    text2:
      "Pour optimiser le potentiel des énergies renouvelables, transformer les systèmes électriques dans le monde entier et décarboner le réseau électrique, nous devons résoudre le problème de l'intermittence des énergies renouvelables.",
    buttonText: "Solutions",
  },
  ja: {
    headingWithLine: "私たちの課題",
    limeText: "エネルギーは、気候危機への取組みの核心です。",
    text1: "世界の温室効果ガスの4分の1は、発電によって排出されています。",
    text2:
      "持続可能なエネルギーの潜在能力を発揮し、世界中の電力システムを変革し、送配電を脱炭素化するためには、再生可能エネルギーの不安定性を解決する必要があります。",
    buttonText: "ソリューション",
  },
};

export const energy = {
  en: {
    preHeading: "What we do",
    heading: {
      text: "We make energy",
      gradientText: "sustainable",
    },
  },
  de: {
    preHeading: "Unsere Arbeit",
    heading: {
      text: "Wir machen Energie",
      gradientText: "nachhaltig",
    },
  },
  fr: {
    preHeading: "Ce que nous faisons",
    heading: {
      text: "Nous rendons l'énergie",
      gradientText: "durable",
    },
  },
  ja: {
    preHeading: "私たちの取組み",
    heading: {
      text: "私たちはエネルギーを持続可能なものにします。",
    },
  },
  animData: energyAnimData,
};

export const diagrams = {
  mainAnimData: diagramsAnimData,
  en: [
    {
      heading: "For suppliers.",
      text: "Helping manage risk and giving freedom to invest and innovate.",
      animData: windmillAnimData,
    },
    {
      heading: "For grid infrastructure.",
      text: "Managing complexities around supply and demand at scale.",
      animData: pylonAnimData,
    },
    {
      heading: "For people.",
      text: "Reducing the long-term need for subsidies and helping to keep renewable energy prices at affordable levels.",
      animData: plugAnimData,
    },
  ],
  de: [
    {
      heading: "Für erneuerbare Stromproduzenten",
      text: "Wir helfen beim Risikomanagement und schaffen Freiräume für Investitionen und Innovationen.",
      animData: windmillAnimData,
    },
    {
      heading: "Für Netzbetreiber",
      text: "Wir reduzieren die Komplexität rund um Angebot und Nachfrage.",
      animData: pylonAnimData,
    },
    {
      heading: "Für die Gesellschaft",
      text: "Wir helfen dabei die Kosten der Energiewende zu reduzieren, um erneuerbare Energie für alle noch attraktiver zu machen.",
      animData: plugAnimData,
    },
  ],
  fr: [
    {
      heading: "Pour les fournisseurs.",
      text: "Aider à gérer les risques et donner la liberté d'investir et d'innover.",
      animData: windmillAnimData,
    },
    {
      heading: "Pour les infrastructures de réseau.",
      text: "Gérer la complexité de l'offre et de la demande à grande échelle.",
      animData: pylonAnimData,
    },
    {
      heading: "Pour les personnes",
      text: "Réduire le besoin de subventions à long terme et contribuer à maintenir les prix des énergies renouvelables à des niveaux abordables.",
      animData: plugAnimData,
    },
  ],
  ja: [
    {
      heading: "サプライヤ―の皆様のために",
      text: "私たちは、リスク管理を支援し、投資と革新のための自由を提供します。",
      animData: windmillAnimData,
    },
    {
      heading: "送配電網のインフラ整備のために",
      text: "私たちは、大規模な電力需給に伴う複雑な問題に対応します。",
      animData: pylonAnimData,
    },
    {
      heading: "社会のために",
      text: "補助金への長期的な依存度を減らし、再生可能エネルギーの価格を手頃な水準に維持することに貢献します。",
      animData: plugAnimData,
    },
  ],
};

export const skills = {
  en: {
    heading:
      "We have the skills and tools to manage long-term structural change.",
    cards: [
      {
        heading: "In-the-moment execution",
        text: "We react in real-time to balance the extreme changeability of renewable energy and capture opportunities in the commodity markets created by the transition to sustainable energy.",
        animData: executionAnimData,
      },
      {
        heading: "Optimization",
        text: "We use our expertise, analytics and proprietary software to optimize assets and manage risk over the short to medium term.",
        animData: optimisationAnimData,
      },
      {
        heading: "Innovation",
        text: "We are constantly developing new tools to help us lead the global transition to a renewable energy future.",
        animData: innovationAnimData,
      },
    ],
  },
  de: {
    heading:
      "Wir haben die Kompetenzen und das richtige Know-how, um die Energiewende erfolgreich umzusetzen.",
    cards: [
      {
        heading: "Echtzeit Abwicklung",
        text: "Wir reagieren in Echtzeit und nutzen Chancen im Energiehandelsmarkt, um die volatile Erzeugung aus erneuerbarer Energie auszugleichen.",
        animData: executionAnimData,
      },
      {
        heading: "Optimierung",
        text: "Wir nutzen unsere Kompetenz, Analysefähigkeiten sowie unsere propriätere Software für die Optimierung und das Risikomanagement von erneuerbaren Energieanlagen.",
        animData: optimisationAnimData,
      },
      {
        heading: "Innovation",
        text: "Wir entwickeln neue Produkte, mit denen wir den weltweiten Übergang zu einer Zukunft mit erneuerbaren Energien anführen können.",
        animData: innovationAnimData,
      },
    ],
  },
  fr: {
    heading:
      "Nous disposons des compétences et des outils nécessaires pour gérer les changements structurels à long terme.",
    cards: [
      {
        heading: "Exécution en temps réel",
        text: "Nous réagissons en temps réel pour équilibrer l'extrême variabilité des énergies renouvelables et saisir les opportunités créées par la transition vers une énergie durable sur les marchés des matières premières.",
        animData: executionAnimData,
      },
      {
        heading: "Optimisation",
        text: "Nous utilisons notre expertise, nos analyses et nos logiciels propriétaires  pour optimiser les actifs et gérer les risques à court et moyen terme.",
        animData: optimisationAnimData,
      },
      {
        heading: "Innovation",
        text: "Nous développons en permanence de nouveaux outils pour nous aider à mener la transition mondiale vers un avenir fondé sur les énergies renouvelables.",
        animData: innovationAnimData,
      },
    ],
  },
  ja: {
    heading:
      "私たちは、長期的な構造変化に対応するスキルとツールを有しています。",
    cards: [
      {
        heading: "即時の遂行",
        text: "私たちは、再生可能エネルギーの不安定性を解消するとともに、持続可能なエネルギーへの移行によって生じる商品市場での機会を掴むために、リアルタイムで対応します。",
        animData: executionAnimData,
      },
      {
        heading: "最適化",
        text: "私たちは、専門知識、数々の分析手法及び独自のソフトウェアを駆使して、短期から中期にわたる資産の最適化とリスク管理を行います。",
        animData: optimisationAnimData,
      },
      {
        heading: "イノベーション",
        text: "私たちは、再生可能エネルギーの未来への世界規模での移行をリードするために、常に新しいツールを開発しています。",
        animData: innovationAnimData,
      },
    ],
  },
};

export const vision = {
  en: {
    heading: "How are we equipped to make this vision a reality?",
    items: [
      {
        heading: "Energy market expertise",
        paragraphs: [
          "We’re a highly experienced team with skillsets that span across physical asset management, trading, quantitative analysis, meteorology and software engineering and development.",
        ],
      },
      {
        heading: "Data-driven insights",
        paragraphs: [
          "Our unparalleled reservoir of data is at the core of our modelling and analytics capabilities, enabling continuous innovation and improvement.",
        ],
      },
      {
        heading: "Agility and scalability",
        paragraphs: [
          "We enhance our expertise and knowledge with the smart use of proprietary, scalable technology to efficiently process vast amounts of data, execute trades, manage renewable energy production and reduce operational risk.  We have the agility and capacity to scale up our platform to keep pace with the rapidly-evolving transition to renewable energy.",
        ],
      },
      {
        heading: "Risk transfer and sharing",
        paragraphs: [
          "We deploy our balance sheet for and with customers to maximise revenues, minimize costs and manage risk.",
        ],
      },
      {
        heading: "Independence",
        paragraphs: [
          "We are privately owned and independent.  We are a trusted partner, working together with our clients, not in competition.  For this reason, we do not own renewable power generation assets.",
        ],
      },
    ],
  },
  de: {
    heading: "Wie können wir diese Vision wahr werden lassen?",
    items: [
      {
        heading: "Expertise im Energiemarkt",
        paragraphs: [
          "Wir sind sehr erfahren im physischen Assetmanagement, in quantitativen Analysen, Trading, Meteorologie sowie Softwareentwicklung.",
        ],
      },
      {
        heading: "Datenbasiertes Wissen",
        paragraphs: [
          "Unsere einmalige Datenbank steht im Mittelpunkt unserer Analyse- und Modellierungsfähigkeiten. Dadurch werden ständige Verbesserungen und Innovationen ermöglicht.",
        ],
      },
      {
        heading: "Agilität und Skalierbarkeit",
        paragraphs: [
          "Wir erweitern unsere Kompetenzen durch den Einsatz von Technologie um große Datenmengen effizient zu verarbeiten, Geschäfte abzuwickeln sowie die Erzeugung von erneuerbarer Energie zu verwalten. Wir verfügen über die notwendige Anpassungsfähigkeit um den schnellen Ausbau von erneuerbarer Energie zu unterstützen.",
        ],
      },
      {
        heading: "Umsatz- sowie Risikomanagement",
        paragraphs: [
          "Wir helfen unseren Kunden Umsätze zu maximieren, Kosten zu minimieren und Risiken zu steuern.",
        ],
      },
      {
        heading: "Unabhängigkeit",
        paragraphs: [
          "Unser Unternehmen ist im Privatbesitz und unabhängig. Wir sind ein zuverlässiger Partner, stehen nicht im Wettbewerb zu unseren Kunden, sondern pflegen enge Beziehungen mit ihnen.",
        ],
      },
    ],
  },
  fr: {
    heading:
      "Comment sommes-nous équipés pour faire de cette vision une réalité ?",
    items: [
      {
        heading: "Expertise du marché de l'énergie",
        paragraphs: [
          "Nous formons une équipe très expérimentée dont les compétences s'étendent de la gestion des actifs physiques, au commerce, à l'analyse quantitative, à la météorologie et à l'ingénierie et au développement de logiciels.",
        ],
      },
      {
        heading: " Des recommandations pilotées par les données",
        paragraphs: [
          "Notre réservoir inégalé de données est au cœur de nos capacités de modélisation et d'analyse, permettant une innovation et une amélioration continues.",
        ],
      },
      {
        heading: "Agilité et passage à l’é",
        paragraphs: [
          "Nous renforçons notre expertise et nos connaissances par l'utilisation intelligente d'une technologie propriétaire et évolutive pour traiter efficacement de grandes quantités de données, exécuter des transactions, gérer la production d'énergie renouvelable et réduire le risque opérationnel.  Nous avons la souplesse et la capacité de faire évoluer notre plateforme pour suivre  l'évolution rapide de la transition vers les énergies renouvelables.",
        ],
      },
      {
        heading: "Transfert et partage des risques",
        paragraphs: [
          "Nous déployons notre bilan pour et avec les clients afin de maximiser les revenus, minimiser les coûts et gérer les risques.",
        ],
      },
      {
        heading: "Indépendance",
        paragraphs: [
          "Nous sommes une entreprise privée et indépendante.  Nous sommes un partenaire de confiance, qui travaille en collaboration avec ses clients, et non en concurrence.  C'est pourquoi nous ne possédons pas d'actifs de production d'énergie renouvelable.",
        ],
      },
    ],
  },
  ja: {
    heading: "このビジョンを実現するために、私たちが提供できるもの",
    items: [
      {
        heading: "エネルギー市場における専門性",
        paragraphs: [
          "私たちは、現物資産管理、トレーディング、定量分析、気象学やソフトウェアエンジニアリング・開発など、さまざまなスキルを持つ経験豊かなチームです。",
        ],
      },
      {
        heading: "データに基づく洞察",
        paragraphs: [
          "比類なきデータの蓄積が、モデリングと分析能力の核となり、継続的なイノベーションと改善を可能にしています。",
        ],
      },
      {
        heading: "敏捷な対応及び大規模化への対応力",
        paragraphs: [
          "私たちは、膨大な量のデータを効率的に処理し、取引を実行し、再生可能エネルギーの生産を管理し、運用リスクを低減するために、独自の拡張可能なテクノロジーを賢く利用することで専門性と知見を高めています。私たちは、急速に進化する再生可能エネルギーへの移行に対応する敏捷性や、プラットフォームを拡大する力を有しています。",
        ],
      },
      {
        heading: "リスク移転及びリスク共有",
        paragraphs: [
          "私たちは、お客様のために、またお客様とともに私たちのバランスシートを活用し、収益の最大化、コストの最小化及びリスクの管理を行います。",
        ],
      },
      {
        heading: "独立性",
        paragraphs: [
          "私たちは、非公開会社であり独立した会社です。お客様とともに働く信頼できるパートナーであり、競争相手ではありません。そのため、私たちは再生可能エネルギー発電に関する資産を所有していません。",
        ],
      },
    ],
  },
};

export const join = {
  image: {
    src: "/images/join-image.jpg",
    alt: "Join the team",
  },
  en: {
    heading:
      "We're always on the lookout for talent to join our dynamic global team.",
    buttonText: "Join the team",
  },
  de: {
    heading:
      "Wir suchen immer nach neuen kompetenten Mitarbeitern für unser dynamisches und weltweit tätiges Team.",
    buttonText: "Kommen Sie ins Team",
  },
  fr: {
    heading:
      "Nous sommes toujours à la recherche de talents pour rejoindre notre dynamique équipe mondiale.",
    buttonText: "Rejoignez l'équipe",
  },
  ja: {
    heading:
      "私たちは、ダイナミックかつグローバルなチームの一員となる才能を常に求めています。",
    buttonText: "チームに参画する",
  },
};
