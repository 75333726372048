import Ticker from "./Ticker";

function HomepageTicker({ locale, tickerData }) {
  const tickerText = {
    en: tickerData.tickerItemEn,
    de: tickerData.tickerItemDe,
    fr: tickerData.tickerItemFr,
    ja: tickerData.tickerItemJa,
  };

  if (tickerData.tickerUrl) {
    return (
      <Ticker
        tickerItem={tickerText[locale] ? tickerText[locale] : tickerText["en"]}
        href={tickerData.tickerUrl}
      />
    );
  } else {
    return (
      <Ticker
        tickerItem={tickerText[locale] ? tickerText[locale] : tickerText["en"]}
      />
    );
  }
}

export default HomepageTicker;
