export const ABOUT_URL = "/about";
export const CAREERS_URL = "/careers";
export const CONTACT_URL = "/contact";
export const CORPORATE_URL = "/corporate";
export const CUSTOMER_PORTAL_URL = "https://portal.trailstonegroup.com/";
export const HOME_URL = "/";
export const NATURAL_GAS_URL = "/solutions#natural-gas";
export const NEWS_URL = "/latest-news";
export const PARTNERSHIPS_URL = "/solutions#partnerships";
export const RENEWABLES_URL = "/solutions#renewables";
export const SOLUTIONS_URL = "/solutions";
export const SUSTAINABILITY_URL = "/sustainability";
