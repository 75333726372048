import { useRef } from "react";

import AnimatedHeadingGradientLast from "./AnimatedHeadingGradientLast";
import Box from "./Box";
import BoxWithGutter from "./BoxWithGutter";
import HeadingWithLine from "./HeadingWithLine";
import LottiePlayer from "./LottiePlayer";
import NarrowCol from "./NarrowCol";
import { energy } from "../content/index";
import { useLottieScroll } from "../core/hooks";

function HomepageEnergy({ locale }) {
  const animContainerRef = useRef();
  const playerRef = useRef();
  const energyContent = energy[locale];
  useLottieScroll(animContainerRef, playerRef, 30, true);

  return (
    <BoxWithGutter
      background="linear-gradient(
    180deg
    , #05004f, #030034 53%, #020027)"
    >
      <NarrowCol display="flex" justifyContent="space-between">
        <Box
          display="flex"
          flex={1}
          pt={[70, null, 0, null]}
          flexDirection="column"
          justifyContent="center"
        >
          <Box pb={["5px", null, 40, null]}>
            <HeadingWithLine fontSize={[12, 12, 22, 22]}>
              {energyContent.preHeading}
            </HeadingWithLine>
          </Box>

          <AnimatedHeadingGradientLast
            text={energyContent.heading.text}
            gradientText={
              energyContent.heading.gradientText
                ? energyContent.heading.gradientText
                : ""
            }
          />
        </Box>
        <Box
          display={["none", null, "initial", null]}
          width="40%"
          ref={animContainerRef}
          overflow="hidden"
        >
          <Box mt={-60}>
            <LottiePlayer
              ref={playerRef}
              autoplay={false}
              loop={false}
              src={energy.animData}
              rendererSettings={{
                preserveAspectRatio: "xMidYMid meet",
              }}
              pauseWhenNotInView={false}
            />
          </Box>
        </Box>
      </NarrowCol>
    </BoxWithGutter>
  );
}

export default HomepageEnergy;
