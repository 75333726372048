import { useRef } from "react";
import { useLottieScroll, useViewportSize } from "../core/hooks";

import Box from "./Box";
import LottiePlayer from "./LottiePlayer";

function TwoColLottieScrollToPlay({ animData, col1Content }) {
  const { innerHeight } = useViewportSize();
  const col1Ref = useRef();
  const playerRef = useRef();
  useLottieScroll(col1Ref, playerRef, 33.333, false);

  return (
    <Box display="flex">
      <Box width={[null, null, "50%", null]} ref={col1Ref}>
        {col1Content}
      </Box>
      <Box
        width={[null, null, "50%", null]}
        position={[null, null, "sticky", null]}
        top={0}
        height={[null, null, innerHeight, null]}
        display="flex"
        justifyContent="center"
        alignItems="center"
        overflow="hidden"
      >
        <LottiePlayer
          ref={playerRef}
          autoplay={false}
          loop={false}
          src={animData}
          rendererSettings={{
            preserveAspectRatio: "xMidYMid meet",
          }}
          pauseWhenNotInView={false}
        />
      </Box>
    </Box>
  );
}

export default TwoColLottieScrollToPlay;
