import { useEffect, useRef, useState } from "react";

import BodyTextLarge from "./BodyTextLarge";
import Box from "./Box";
import BoxWithGutter from "./BoxWithGutter";
import GreenButton from "./GreenButton";
import HeadingWithLine from "./HeadingWithLine";
import Image from "./Image";
import { InView } from "react-intersection-observer";
import { SOLUTIONS_URL } from "../core/urls";
import Video from "./Video";
import { challenge } from "../content/index";
import { mapValues } from "../utils/utils";
import { useViewportSize } from "../core/hooks";

function HomepageChallenge({ locale }) {
  const { innerHeight } = useViewportSize();
  const [videoReady, setVideoReady] = useState(false);
  const [playVideo, setPlayVideo] = useState(false);
  const videoContainerRef = useRef();
  const videoRef = useRef();
  const playPromiseRef = useRef();
  const tintRef = useRef();
  const textContainerRef = useRef();
  const loopRef = useRef();
  const rafRef = useRef();
  const scaleWithInertiaRef = useRef();
  const challengeContent = challenge[locale];

  loopRef.current = () => {
    if (videoContainerRef.current && textContainerRef.current) {
      const { height: videoContainerHeight, top: videoContainerTop } =
        videoContainerRef.current.getBoundingClientRect();
      const scale = mapValues(
        videoContainerTop,
        videoContainerHeight,
        0,
        0.8,
        1
      );

      const { height: textContainerHeight, top: textContainerTop } =
        textContainerRef.current.getBoundingClientRect();
      const tintOpacity = mapValues(
        textContainerTop,
        textContainerHeight,
        0,
        0,
        1
      );

      if (scaleWithInertiaRef.current == null) {
        scaleWithInertiaRef.current = scale;
      } else {
        scaleWithInertiaRef.current +=
          (scale - scaleWithInertiaRef.current) / 5;
      }

      videoContainerRef.current.style.transform = `scale3d(${scaleWithInertiaRef.current},${scaleWithInertiaRef.current},1)`;
      tintRef.current.style.opacity = tintOpacity;
    }

    rafRef.current = requestAnimationFrame(loopRef.current);
  };

  useEffect(() => {
    rafRef.current = requestAnimationFrame(loopRef.current);

    return () => {
      cancelAnimationFrame(rafRef.current);
    };
  }, []);

  useEffect(() => {
    if (playVideo) {
      playPromiseRef.current = videoRef.current.play();
    } else {
      // If play has already been called check that the promise has been resolved
      // before attempting to call pause
      // https://developers.google.com/web/updates/2017/06/play-request-was-interrupted
      if (playPromiseRef.current && playPromiseRef.current !== undefined) {
        playPromiseRef.current.then(() => {
          videoRef.current.pause();
          playPromiseRef.current = null;
        });
      }
    }
  }, [playVideo]);

  return (
    <Box>
      <Box
        position="sticky"
        top={0}
        width="100%"
        height="100vh"
        overflow="hidden"
        zIndex={1}
      >
        <Box ref={videoContainerRef} height="100%" position="relative">
          <InView
            as="div"
            onChange={(inView) => {
              setPlayVideo(inView);
            }}
          ></InView>
          <Video
            ref={videoRef}
            position="absolute"
            height="100%"
            width="100%"
            top={0}
            left={0}
            zIndex={1}
            src={challenge.videoSrc}
            muted
            playsInline
            loop
            onPlaying={() => {
              if (!videoReady) setVideoReady(true);
            }}
          />
          <Image
            position="absolute"
            height="100%"
            width="100%"
            top={0}
            left={0}
            zIndex={2}
            objectFit="cover"
            alt="Homepage challenge"
            src={challenge.imageSrc}
            display={videoReady ? "none" : "block"}
          />
          <Box
            ref={tintRef}
            backgroundColor="rgba(2,0,39,0.6)"
            position="absolute"
            height="100%"
            width="100%"
            top={0}
            left={0}
            zIndex={3}
          />
        </Box>
      </Box>

      <Box ref={textContainerRef} id="homepage-anchor">
        <BoxWithGutter
          height={innerHeight}
          zIndex={1}
          position="relative"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Box width="100%" maxWidth={[300, 760, null, null]} mx="auto">
            <HeadingWithLine fontSize={[12, 12, 20, 20]}>
              {challengeContent.headingWithLine}
            </HeadingWithLine>
            <BodyTextLarge pt={[30, null, 40, null]} color="lime">
              {challengeContent.limeText}
            </BodyTextLarge>{" "}
            <BodyTextLarge pb={[30, null, 40, null]}>
              {challengeContent.text1}
            </BodyTextLarge>
            <BodyTextLarge pb={[30, null, 40, null]}>
              {challengeContent.text2}
            </BodyTextLarge>
            <GreenButton href={SOLUTIONS_URL}>
              {challengeContent.buttonText}
            </GreenButton>
          </Box>
        </BoxWithGutter>
      </Box>
    </Box>
  );
}

export default HomepageChallenge;
