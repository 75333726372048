import React, { useEffect, useRef } from "react";

import Box from "./Box";
import BoxWithGutter from "./BoxWithGutter";
import styled from "styled-components";

const TickerItemText = styled.p`
  color: #020a66;
  font-family: "EuclidCircularB-Regular";
  padding-right: 12px;
  white-space: nowrap;
  line-height: 28px;
`;

const TickerLink = styled.a`
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #3affc6;
    transition: background-color 300ms;
    border-radius: 10px;
    z-index: -1;
    top: 0;
  }
  body:not(.is-touch) & {
    :hover {
      &::after {
        background-color: white;
      }
    }
  }
`;

function Ticker({ tickerItem, href }) {
  const spansRef = useRef([]);

  useEffect(() => {
    const spanWidth = spansRef.current[0].getBoundingClientRect().width;
    const animationDuration = Math.round((spanWidth / 350) * 10);

    spansRef.current.forEach((span) => {
      span.style.animationDuration = `${animationDuration}s`;
    });
  }, []);

  let items = [];

  for (let i = 0; i < 15; i++) {
    items.push(tickerItem);
  }

  return (
    <BoxWithGutter width="100%" zIndex={100} position="fixed" top={78}>
      <Box position="relative">
        <TickerLink href={href} target="_blank" rel="noreferrer">
          <Box
            height={60}
            width="100%"
            overflow="hidden"
            display="flex"
            alignItems="center"
            borderRadius={"10px"}
            py={16}
            pl={19}
          >
            {items.map((item, index) => {
              return (
                <span
                  key={index}
                  className="marquee-animation"
                  ref={(el) => (spansRef.current[index] = el)}
                >
                  <Box>
                    <TickerItemText>{item}</TickerItemText>
                  </Box>
                </span>
              );
            })}
          </Box>
        </TickerLink>
      </Box>
    </BoxWithGutter>
  );
}

export default Ticker;
