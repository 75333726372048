import Box from "./Box";
import CapSize from "./CapSize";

import { useEnteredViewport } from "../core/hooks";

function HeadingWithLine({ fontSize, children }) {
  const { ref: lineRef, entered } = useEnteredViewport(1);

  return (
    <Box display="flex" alignItems="center">
      <CapSize fontSize={fontSize}>{children}</CapSize>
      <Box
        ref={lineRef}
        transition="transform 300ms cubic-bezier(0.16, 1, 0.3, 1)"
        transform={entered ? "scale3d(1,1,1)" : "scale3d(0,1,1)"}
        transformOrigin="left"
        height="2px"
        width={100}
        ml="10px"
        mt={["0.2em", null, "0.3em", null]}
        backgroundColor="cyan"
      ></Box>
    </Box>
  );
}

export default HeadingWithLine;
