import { CAREERS_URL } from "../core/urls";
import CapSize from "./CapSize";
import GreenButton from "./GreenButton";
import ImageBackgroundAndLines from "./ImageBackgroundAndLines";
import { join } from "../content/index";

function HomepageJoin({ locale }) {
  const joinContent = join[locale];
  return (
    <ImageBackgroundAndLines
      image={join.image}
      backgroundColor="trailstoneNavy"
    >
      <CapSize
        fontFamily="bold"
        fontSize={[30, 48, 48, 62]}
        lineHeight={["40px", "58px", "58px", "68px"]}
        textAlign="center"
        maxWidth={[280, 1010, null, null]}
        pb={[20, null, 30, null]}
      >
        {joinContent.heading}
      </CapSize>
      <GreenButton href={CAREERS_URL}>{joinContent.buttonText}</GreenButton>
    </ImageBackgroundAndLines>
  );
}

export default HomepageJoin;
