import { layout, position } from "styled-system";

import styled from "styled-components";

const Video = styled.video`
  ${layout}
  ${position}
  object-fit: cover;
`;

export default Video;
