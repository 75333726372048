import { metaDescription, metaTitle } from "../content/index";

import Head from "next/head";
import HomepageChallenge from "../components/HomepageChallenge";
import HomepageDiagrams from "../components/HomepageDiagrams";
import HomepageEnergy from "../components/HomepageEnergy";
import HomepageHero from "../components/HomepageHero";
import HomepageJoin from "../components/HomepageJoin";
import HomepageSkills from "../components/HomepageSkills";
import HomepageVision from "../components/HomepageVision";
import HomepageTicker from "../components/HomepageTicker";
import Metatags from "../components/Metatags";
import { useRouter } from "next/router";
import { getTickerData } from "../core/data";
import Errors from "../components/Errors";

export default function Home({
  data: {
    wagtail: { globalSiteSettings },
  },
  errors,
}) {
  const { locale } = useRouter();
  if (errors.length) {
    return <Errors errors={errors} />;
  }
  return (
    <>
      <Head>
        <title>Trailstone | Home</title>
        <Metatags
          metaTitle={metaTitle[locale]}
          metaDescription={metaDescription[locale]}
        />
      </Head>
      {globalSiteSettings.tickerShow && (
        <HomepageTicker locale={locale} tickerData={globalSiteSettings} />
      )}

      <HomepageHero locale={locale} />
      <HomepageChallenge locale={locale} />
      <HomepageEnergy locale={locale} />
      <HomepageDiagrams locale={locale} />
      <HomepageSkills locale={locale} />
      <HomepageVision locale={locale} />
      <HomepageJoin locale={locale} />
    </>
  );
}

export async function getStaticProps() {
  const { data, errors } = await getTickerData();

  return {
    props: {
      data,
      errors,
    },
    revalidate: 60,
  };
}
