import { useEffect, useRef } from "react";

import Box from "../components/Box";
import { mapValues } from "../utils/utils";

function FadeOnScroll({ children }) {
  const elRef = useRef();

  const loopRef = useRef();
  const rafRef = useRef();

  loopRef.current = () => {
    if (elRef.current) {
      const { height } = elRef.current.getBoundingClientRect();
      const opacity = mapValues((window.scrollY / height) * 100, 0, 75, 1, 0);
      elRef.current.style.opacity = opacity;
    }

    rafRef.current = requestAnimationFrame(loopRef.current);
  };

  useEffect(() => {
    rafRef.current = requestAnimationFrame(loopRef.current);

    return () => {
      cancelAnimationFrame(rafRef.current);
    };
  }, []);

  return <Box ref={elRef}>{children}</Box>;
}

export default FadeOnScroll;
